<template>
    <div class="main">
        <div class="title">{{ title }}</div>
        <div class="top">
            <el-form
                :model="formData"
                :rules="rules"
                ref="form"
                label-width="125px"
                label-position="right"
                size="small"
                inline
            >
                <el-form-item prop="name" label="专利名称">
                    <el-input v-model="formData.name" placeholder="请输入专利名称"></el-input>
                </el-form-item>
                <el-form-item prop="applyTime" label="申请日期" style="margin-left: 122px">
                    <el-date-picker
                        style="width: 200px"
                        v-model="formData.applyTime"
                        type="date"
                        value-format="yyyy-MM-dd"
                        placeholder="请选择"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item prop="code" label="专利申请号">
                    <el-input v-model="formData.code" placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item prop="ipc" label="IPC分类号" style="margin-left: 122px">
                    <el-input style="width: 200px" v-model="formData.ipc" placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item
                    v-if="caseType === 'GENERAL'"
                    prop="patentTypeId"
                    label="专利类型"
                    style="margin-right: 133px"
                >
                    <el-select
                        style="width: 200px"
                        v-model="formData.patentTypeId"
                        clearable
                        filterable
                        placeholder="请选择"
                    >
                        <el-option
                            v-for="item in patentTypeOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item prop="industryClass" v-if="caseType === 'DECLASSIFICATION'" label="行业分类">
                    <el-select v-model="formData.industryClass" placeholder="请选择" style="width: 200px">
                        <el-option
                            v-for="item in industryOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item prop="industryClass" v-if="caseType === 'GENERAL'" label="行业分类">
                    <el-select v-model="formData.industryClass" placeholder="请选择" style="width: 200px">
                        <el-option
                            v-for="item in industryOption"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <!-- <el-form-item v-if="caseType === 'GENERAL'" prop="ownerType" label="专利权人类型">
                    <el-select
                        style="width: 200px"
                        v-model="formData.ownerType"
                        clearable
                        filterable
                        placeholder="请选择"
                    >
                        <el-option
                            v-for="item in ownerTypeOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item> -->
                <el-form-item prop="lawStatusId" label="法律状态">
                    <el-select
                        v-model="formData.lawStatusId"
                        clearable
                        filterable
                        placeholder="请选择"
                        style="width: 200px"
                    >
                        <el-option
                            v-for="item in patentStatusOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item v-if="caseType === 'GENERAL'" prop="applicant" label="申请人">
                    <el-select
                        v-model="formData.applicant"
                        multiple
                        class="suffix"
                        filterable
                        allow-create
                        default-first-option
                        placeholder="请输入申请人，按回车分割"
                        clearable
                        popper-class="hideSelect"
                        style="width: 400px; margin-right: 82px"
                    >
                    </el-select>
                </el-form-item>
                <el-form-item prop="owner" style="margin-right: 82px" label="专利权人" class="block">
                    <el-select
                        class="suffix"
                        style="width: 400px"
                        v-model="formData.owner"
                        multiple
                        filterable
                        allow-create
                        default-first-option
                        placeholder="请输入专利权人，按回车分割"
                        clearable
                        popper-class="hideSelect"
                    >
                    </el-select>
                </el-form-item>

                <el-form-item prop="inventor" label="发明人" class="block" style="margin-right: 142px">
                    <el-select
                        class="suffix"
                        style="width: 400px"
                        v-model="formData.inventor"
                        multiple
                        filterable
                        allow-create
                        default-first-option
                        placeholder="请输入发明人，按回车分割"
                        clearable
                        popper-class="hideSelect"
                    >
                        <div slot="empty"></div>
                    </el-select>
                </el-form-item>
                <el-form-item prop="img" label="专利附图" class="block">
                    <multi-upload class="imgBox" v-model="formData.img" :limit="6">
                        请上传产品主图片，格式JPG或PNG，尺寸500x500px，大小不超过1M，最多可上传6张
                    </multi-upload>
                </el-form-item>
                <el-form-item prop="digest" label="专利摘要" class="block">
                    <el-input
                        style="width: 400px"
                        type="textarea"
                        v-model="formData.digest"
                        maxlength="300"
                        show-word-limit
                        :autosize="{ minRows: 6, maxRow: 6 }"
                        placeholder="请填写专利摘要描述，300字以内"
                        resize="none"
                    ></el-input>
                </el-form-item>

                <el-form-item prop="manual" label="专利说明" class="block">
                    <rich-text v-model="formData.manual"></rich-text>
                </el-form-item>

                <el-form-item prop="tradingMethodId" label="交易方式" class="block">
                    <el-radio-group v-model="formData.tradingMethodId">
                        <el-radio v-for="item in tradingMethodOption" :key="item.value" :label="item.value">
                            {{ item.label }}
                        </el-radio>
                    </el-radio-group>
                </el-form-item>

                <el-form-item prop="expectedPrice" label="期望价（万元）">
                    <el-input-number
                        v-model="formData.expectedPrice"
                        :disabled="formData.negotiateDirectly"
                        @change="Price"
                        :precision="1"
                        :controls="false"
                        placeholder="请输入"
                        style="width: 200px; text-align: left"
                    >
                    </el-input-number>

                    <el-checkbox @change="Directly" style="margin-left: 10px" v-model="formData.negotiateDirectly"
                        >面议</el-checkbox
                    >
                </el-form-item>

                <el-form-item prop="contact" label="联系人" style="margin-left: 58px">
                    <el-input v-model="formData.contact" placeholder="请输入联系人"></el-input>
                </el-form-item>

                <el-form-item prop="basePrice" label="底价（万元）">
                    <el-input-number
                        style="width: 200px"
                        v-model="formData.basePrice"
                        :min="0"
                        :precision="1"
                        placeholder="请输入"
                        :controls="false"
                    >
                    </el-input-number>
                </el-form-item>

                <el-form-item prop="phone" label="联系电话" style="margin-left: 120px">
                    <el-input v-model="formData.phone" placeholder="请输入联系电话"></el-input>
                </el-form-item>

                <el-form-item prop="commissionType" label="佣金类型">
                    <el-radio-group v-model="formData.commissionType">
                        <el-radio v-for="item in commissionTypeOptions" :key="item.value" :label="item.value">
                            {{ item.label }}
                        </el-radio>
                    </el-radio-group>
                </el-form-item>

                <el-form-item prop="email" label="邮箱" style="margin-left: 164px">
                    <el-input v-model="formData.email" placeholder="请输入邮箱"></el-input>
                </el-form-item>

                <el-form-item
                    style="margin-right: 112px"
                    prop="commission"
                    :label="`佣金${formData.commissionType == 'COMMISSION' ? '（万元）' : '（%）'}`"
                >
                    <el-input v-model="formData.commission" placeholder="请输入"></el-input>
                </el-form-item>

                <el-form-item prop="address" label="所在地区" class="block">
                    <el-input style="width: 400px" v-model="formData.address" placeholder="请输入所在地址"></el-input>
                </el-form-item>
            </el-form>
            <div class="btn">
                <el-button type="primary" @click="onSave">确定</el-button>
                <el-button @click="onDelete" :disabled="saving" type="danger" v-if="formData.id"> 删除 </el-button>
                <el-button type="info" @click="$router.go(-1)">返回</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import MultiUpload from '../../../components/MultiUpload.vue';
import RichText from '../../../components/RichText.vue';
import {
    patentTypeOptions,
    patentStatusOptions,
    ownerTypeOptions,
    tradingMethodOption,
    commissionTypeOptions,
    phonePattern
} from '../../../utils/variables';
import { mapState } from 'vuex';
export default {
    components: { RichText, MultiUpload },
    data() {
        var validatePrice = (rule, value, callback) => {
            if (!this.formData.negotiateDirectly) {
                if (!this.formData.expectedPrice) {
                    callback(new Error('请选择期望价方式'));
                } else {
                    callback();
                }
            } else {
                callback();
            }
        };
        return {
            needChange: true,
            saving: false,
            formData: {
                inventor: [],
                owner: [],
                commissionType: 'COMMISSION'
            },
            rules: {
                name: [{ required: true, message: '请输入专利名称', trigger: 'blur' }],
                code: [{ required: true, message: '请输入专利申请号', trigger: 'blur' }],
                // patentTypeId: [{ required: true, message: '请选择专利类型', trigger: 'blur' }],
                // industryClass: [{ required: true, message: '请选择所属领域', trigger: 'blur' }],
                // lawStatusId: [{ required: true, message: '请选择法律状态', trigger: 'blur' }],
                // ownerType: [{ required: true, message: '请输入专利权人类型', trigger: 'blur' }],
                // owner: [{ required: true, message: '请输入专利权人', trigger: 'blur' }],
                // inventor: [{ required: true, message: '请输入发明人', trigger: 'blur' }],
                // img: [{ required: true, message: '请上传专利附图', trigger: 'blur' }],
                // applicant: [{ required: true, message: '请输入申请人', trigger: 'blur' }],
                // digest: [{ required: true, message: '请填写专利摘要', trigger: 'blur' }],
                // manual: [{ required: true, message: '请输入专利说明', trigger: 'blur' }],
                // tradingMethodId: [{ required: true, message: '请选择交易方式', trigger: 'blur' }],
                // expectedPrice: [{ required: true, validator: validatePrice, message: '请选择期望价方式' }],
                // contact: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
                // basePrice: [{ required: true, message: '请输入底价', trigger: 'blur' }],
                // phone: { required: true, pattern: phonePattern, message: '请输入联系电话', trigger: 'blur' },
                // commissionType: [{ required: true, message: '请选择佣金类型', trigger: 'blur' }],
                // email: [{ type: 'email', required: true, message: '请输入邮箱', trigger: 'blur' }],
                // commission: [{ required: true, message: '请输入佣金', trigger: 'blur' }],
                // address: [{ required: true, message: '请输入所在地区', trigger: 'blur' }]
            },
            industryOptions: [],
            industryOption: [],
            patentTypeOptions,
            patentStatusOptions,
            ownerTypeOptions,
            tradingMethodOption,
            commissionTypeOptions,
            inputValue: ''
        };
    },
    computed: {
        ...mapState(['userInfo']),
        caseType() {
            return this.$route.query.caseType;
        },
        title() {
            const list = ['发布供给信息-专利', '发布供给信息-脱密专利'];
            return list[this.caseType == 'GENERAL' ? 0 : 1];
        }
    },
    created() {
        if (this.$route.query.id) {
            this.$http
                .get('patent/get/' + this.$route.query.id)
                .then(res => {
                    this.formData = res;
                    this.formData.owner = res.owner.split(',');
                })
                .catch(e => {
                    console.log(e);
                    this.$message.error(e.error);
                });
        }
        this.$http
            .post('/setting/byFlag', { flag: 13 })
            .then(res => {
                if (res.length > 0) {
                    this.industryOptions = res.map(item => {
                        return {
                            label: item.name,
                            value: item.id
                        };
                    });
                }
            })
            .catch(e => {
                console.log(e);
                this.$message.error(e.error);
            });
        this.$http
            .post('/setting/byFlag', { flag: 1 })
            .then(res => {
                if (res.length > 0) {
                    this.industryOption = res.map(item => {
                        return {
                            label: item.name,
                            value: item.id
                        };
                    });
                }
            })
            .catch(e => {
                console.log(e);
                this.$message.error(e.error);
            });
        this.formData = {
            contact: this.userInfo.nickname,
            phone: this.userInfo.phone,
            email: this.userInfo.email
        };
    },
    methods: {
        Price() {
            if (this.formData.expectedPrice) {
                this.formData.negotiateDirectly = false;
            }
        },
        Directly() {
            if (this.formData.negotiateDirectly == true) {
                this.$set(this.formData, 'expectedPrice', undefined);
            }
            this.$forceUpdate();
        },
        onSave() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.submit();
                    setTimeout(() => {
                        this.$confirm('发布成功，正在等待平台审核', '提示', {
                            confirmButtonText: '继续发布',
                            cancelButtonText: '退出',
                            type: 'warning'
                        }).then(() => {
                            setTimeout(() => {
                                this.$router.push({
                                    path: '/patentEdit',
                                    query: {
                                        ...this.$route.query
                                    }
                                });
                                this.formData = '';
                                this.formData = {
                                    contact: this.userInfo.nickname,
                                    negotiateDirectly: false,
                                    phone: this.userInfo.phone,
                                    email: this.userInfo.email
                                };
                            }, 1000);
                        });
                        // .catch(() => {
                        //     this.show = false;
                        // });
                    }, 1000);
                } else {
                    return false;
                }
            });
        },
        onDelete() {
            this.$alert('删除将无法恢复，确认要删除么？', '警告', { type: 'error' })
                .then(() => {
                    return this.$http.post(`/patent/del/${this.formData.id}`);
                })
                .then(() => {
                    this.$message.success('删除成功');
                    this.$router.go(-1);
                })
                .catch(e => {
                    if (e !== 'cancel') {
                        console.log(e);
                        this.$message.error((e || {}).error || '删除失败');
                    }
                });
        },
        submit() {
            let data = {
                ...this.formData,
                userId: this.userInfo.id,
                caseType: this.caseType,
                status: 'PENDING',
                contact: this.userInfo.nickname,
                phone: this.userInfo.phone,
                email: this.userInfo.email
            };
            // data.owner = data.owner.join(',');
            this.saving = true;
            console.log(data);
            this.$http
                .post('/patent/save', data, { body: 'json' })
                .then(() => {
                    this.saving = false;
                    this.$message.success('发布成功');
                    this.show = false;
                    this.$router.go(-1);
                })
                .catch(e => {
                    console.log(e);
                    this.saving = false;
                    this.$message.error(e.error);
                });
        }
    }
};
</script>

<style lang="less" scoped>
.el-tag {
    margin-bottom: 11px;
}
.el-tag + .el-tag {
    margin-left: 10px;
}
.input-new-tag {
    width: 100%;
    vertical-align: bottom;
}
.imgBox {
    width: 600px;
}
/deep/ .el-input-number .el-input-number {
    text-align: left !important;
}
/deep/ .el-checkbox__inner {
    border-radius: 50%;
}
/deep/ .el-input__inner {
    height: 36px;
    line-height: 36px;
}
/deep/ .el-button {
    width: 100px;
    border-radius: 4px;
    margin-left: 25px;
}
/deep/ .tox-editor-container {
    width: 800px;
}
.suffix {
    /deep/ .el-input__suffix {
        opacity: 0;
    }
}
.main {
    background-color: #fff;
    margin: 17px 20px 0;
    padding-bottom: 60px;
    .btn {
        text-align: right;
    }
    .title {
        font-size: 16px;
        font-weight: bold;
        color: #000000;
        padding-bottom: 17px;
        line-height: 26px;
        border-bottom: 1px solid @bg;
    }
    .top {
        margin-top: 30px;
    }
}
</style>
